import Vue from 'vue'
import App from './App.vue'
import lodash from 'lodash'
import vueLodash from 'vue-lodash'
import store from './store/index'
import CONST from './script/script'
import VALID from './script/validation'
import router from './router'
import edaAxios from './plugins/edaAxios'
import sessionStorageUtils from './utils/SessionStorageUtils'
import amountUtils from './utils/amountUtils'
import VueNumeric from 'vue-numeric'

Vue.config.productionTip = false
Vue.use(vueLodash, {name: 'lds', lodash: lodash})
Vue.use(VueNumeric)

Vue.prototype.$store = store
Vue.prototype.$sessionStorageUtils = sessionStorageUtils
Vue.prototype.$comma = amountUtils.comma
Vue.prototype.ASSETS_TYPE = CONST.ASSETS_TYPE
Vue.prototype.VALID = VALID



new Vue({
  router,
  edaAxios,
  render: h => h(App),
}).$mount('#app')

