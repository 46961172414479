import SessionStorageUtils from "./SessionStorageUtils";
import jwtDecode from "jwt-decode";

const decodeTokenPayload = () => {
  return jwtDecode(SessionStorageUtils.getToken())
}

export default {
  verification: () => {
    return new Promise((resolve, reject) => {
      try {
        const tokenId = decodeTokenPayload().id
        const tokenRole = decodeTokenPayload().role
        const expTime = decodeTokenPayload().exp * 1000
        const nowTime = new Date().getTime()

        let errorMsg = ''
        if (expTime < nowTime) {
          errorMsg = '로그인 유지시간이 만료되었습니다. 다시 로그인해주세요.'
          reject(new Error(errorMsg))
        } else if (tokenId === '') {
          errorMsg = '로그인 아이디 정보가 올바르지 않습니다. 다시 로그인해주세요.'
          reject(new Error(errorMsg))
        } else if (tokenRole === '') {
          errorMsg = '로그인 권한 정보가 올바르지 않습니다. 다시 로그인해주세요.'
          reject(new Error(errorMsg))
        } else {
          sessionStorage.setItem('userId', tokenId)
          resolve()
        }
      } catch (error) {
        console.error(error)
        reject(new Error('로그인 정보를 확인하는 중 오류가 발생했습니다. 다시 로그인해주세요.'))
      }
    })
  }
}
