import axios from 'axios'
import Vue from 'vue'
import router from '../router/index'
import SessionStorageUtils from '../utils/SessionStorageUtils'

let edaAxios = axios.create({
  timeout: 90 * 1000,
  withCredentials: true,
})

Vue.prototype.$Connect =
  (paramUrl, paramMethod, data = {}, params = {}, headers = {}) => {

    // const url = process.env.NODE_ENV === 'production' ? `${location.origin}/client-server` : process.env.VUE_APP_API_URL

    return new Promise((resolve, reject) => {
      edaAxios({
        method: paramMethod,
        // url: `${url}${paramUrl}`,
        url: `${process.env.VUE_APP_API_URL}${paramUrl}`,
        params: params,
        data: data,
        headers: Object.assign(headers, SessionStorageUtils.getToken() === null ? {} : { Authorization: 'Bearer ' + SessionStorageUtils.getToken() })
      }).then(res => {
        if (res.data.success) {
          resolve(res.data.body)
        } else {
          reject(res.data.errMsg)
        }
      }).catch(error => {
        if (error.response.request.status === 401 || error.response.request.status === 403) {
          if (paramUrl === '/v1/login') {
            reject(error.response)
          } else {
            window.alert('로그인 정보를 확인할 수 없습니다. 다시 로그인해주세요.')
            router.push({ name: 'login' })
          }
        } else {
          reject(error.response)
        }
      })
    })
  }

Vue.prototype.$TokenCheck =
  (paramUrl, paramMethod, data = {}, params = {}, headers = {}) => {
    return new Promise((resolve, reject) => {
      edaAxios({
        method: paramMethod,
        url: `${process.env.VUE_APP_API_URL}${paramUrl}`,
        params: params,
        data: data,
        headers: headers
      }).then(res => {
        if (res.status === 200) {
          resolve(res)
        } else {
          reject(res)
        }
      }).catch(error => {
        if (error.response.request.status === 401 || error.response.request.status === 403 || error.response.request.status === 422) {
          reject(error)
        } else {
          console.error(error.response)
          reject(error)
        }
      })
    })
  }

export default edaAxios

