import { map, find, cloneDeep, transform } from 'lodash'
import Vue from 'vue'
import Vuex from 'vuex'
import CONST from '../script/script'
import VALID from '../script/validation'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
// 얼럿 및 컨펌창
    isShowAlert: false,
    alertText: "",
    alertTitle: "",
    alertClickEvent: () => {},
    isShowConfirm: false,
    confirmText: "",
    confirmClickEvent: () => {},
//  상속 비율 직접 입력 유무 
    shareOfInheritanceMethod: false,
// 상승률 수정하기 레이어
    ascentRatePopup: false,
// 현재 LNB dnlcl
    nowLnb: 'ddd',
// 기본정보
    /**
     * [Number] 고객 현재 나이
     *
     * 관련 mutations >
     * updateNowAge: 고객 현재 나이 변경
     *
     * 관련 getter >
     * getNowAge: 고객 현재 나이 가져오기
     */
    nowAge: 0,
    /**
     * [Array] [0]: 1차 상속시점, [1]: 2차 상속시점
     *
     * 관련 mutations >
     * updateFirstInheritanceYearsLater: 1차 상속 시점 (현재로부터 n년 후)
     * updateSecondInheritanceYearsLater: 2차 상속 시점 (1차 상속으로부터 n년 후)
     *
     * 관련 getter >
     * getFirstInheritanceYearsLater: 1차 상속 시점 가져오기 (현재로부터 n년 후)
     * getSecondInheritanceYearsLater: 2차 상속 시점 가져오기 (1차 상속으로부터 n년 후)
     */
    yearsLater: [0, 0],
    /**
     * [Object] 배우자 정보
     *
     * 관련 mutations >
     * switchSpouseExist: 배우자 유무 변경
     * updateSpouseAge: 배우자 현재 나이 변경)
     *
     * 관련 getters >
     * isSpouseExist: 배우자 존재 여부 가져오기 (존재하는 경우 true, 존재하지 않는 경우 false)
     * getSpouseAge: 배우자 현재 나이 가져오기
     */
    spouse: CONST.SPOUSE_DATA(),
    /**
     * [Object] 추가상속인 정보
     *
     * 관련 mutations >
     * updateAdditionalInheritorType: 추가상속인 타입 변경
     * updateAdditionalInheritorCount: 추가상속인 수 변경
     *
     * 관련 getters >
     * getAdditionalInheritorType: 추가상속인 타입 가져오기
     * getAdditionalInheritorCount: 추가상속인 인원수 가져오기 (0: 자녀, 1: 추가상속인)
     */
    additionalInheritor: CONST.ADDITIONAL_INHERITOR_DATA(),
    /**
     * [Boolean] 2차 상속 결과 레포트 노출 유무
     *
     * 관련 mutations >
     * switchVisibleSecondInheritanceTaxReport: 2차 상속 결과 레포트 노출 유무 변경
     *
     * 관련 getters >
     * isVisibleSecondInheritanceTaxReport: 2차 상속 결과 레포트 노출 유무 가져오기
     */
    visibleSecondInheritanceTaxReport: false,
    /**
     * [Array] 상속인 목록
     * 배우자 정보와 상속인 정보에서 도출된 상속인 목록과 상속비율배분율
     *
     * 관련 mutations >
     * updateShareOfInheritancesListByInheritor: 상속 비율 변경(직접입력)
     * resetShareOfInheritancesListByInheritor: 상속 비율 초기화
     *
     * 관련 getters >
     * getShareOfInheritancesListByInheritor: 모든 상속인의 상속배분치 목록
     */
    inheritorList: CONST.INHERITOR_LIST_DATA(),

// 자산
    /**
     * [Object] 자산 정보
     *
     * 관련 mutations >
     * updateAssets: 자산 정보 변경
     * updateCommonAscentRate: 자산 증가치 전체 변경
     *
     * 관련 getters >
     * getCommonAscentRate: 자산 상승률 전체 변경 시 기본 상승률 가져오기
     *
     * getAssetLandAmount: 토지 금액 (n만원 단위) 가져오기
     * getAssetLandAscentRate: 토지 증가치 (% 단위: 최소 0, 최대 100) 가져오기
     * getAssetBuildingAmount: 건물 금액 (n만원 단위) 가져오기
     * getAssetBuildingAscentRate: 건물 증가치 (% 단위: 최소 0, 최대 100) 가져오기
     * getAssetHousingAmount: 주택 금액 (n만원 단위) 가져오기
     * getAssetHousingAscentRate: 주택 증가치 (% 단위: 최소 0, 최대 100) 가져오기
     *
     * getAssetFamilyStockAmount: 가업주식 금액 (n만원 단위) 가져오기
     * getAssetFamilyStockAscentRate: 가업주식 자산 증가치 (% 단위: 최소 0, 최대 100) 가져오기
     *
     * getAssetDepositAmount: 예금 금액 (n만원 단위) 가져오기
     * getAssetDepositAscentRate: 예금 자산 증가치 (% 단위: 최소 0, 최대 100) 가져오기
     * getAssetStockAmount: 주식 금액 (n만원 단위) 가져오기
     * getAssetStockAscentRate: 주식 증가치 (% 단위: 최소 0, 최대 100) 가져오기
     * getAssetOtherFinancialAssetsAmount: 기타금융자산 금액 (n만원 단위) 가져오기
     * getAssetOtherFinancialAssetsAscentRate: 기타금융자산 증가치 (% 단위: 최소 0, 최대 100) 가져오기
     * getAssetInsuranceAssetsAmount: 보험자산 금액 (n만원 단위) 가져오기
     * getAssetInsuranceAssetsAscentRate: 보험자산 증가치 (% 단위: 최소 0, 최대 100) 가져오기
     * getAssetLifeInsuranceAmount: 종신보험금 금액 (n만원 단위) 가져오기
     *
     * getAssetOtherAssetsAmount: 기타자산 금액 (n만원 단위) 가져오기
     * getAssetOtherAssetsAscentRate: 기타자산 증가치 (% 단위: 최소 0, 최대 100) 가져오기
     *
     * getAssetFuneralExpensesAmount: 장례비용 금액 (n만원 단위) 가져오기
     * getAssetFuneralExpensesAscentRate: 장례비용 증가치 (% 단위: 최소 0, 최대 100) 가져오기
     * getAssetFinancialObligationAmount: 채무 금액 (n만원 단위) 가져오기
     * getAssetFinancialObligationAscentRate: 채무 증가치 (% 단위: 최소 0, 최대 100) 가져오기
     *
     * getAssetSpouseNonFinancialAssetsAmount: 배우자 비금융자산 금액 (n만원 단위) 가져오기
     * getAssetSpouseNonFinancialAssetsAscentRate: 배우자 비금융자산 증가치 (% 단위: 최소 0, 최대 100) 가져오기
     * getAssetSpouseFinancialAssetsAmount: 배우자 금융자산 금액 (n만원 단위) 가져오기
     * getAssetSpouseFinancialAssetsAscentRate: 배우자 금융자산 증가치 (% 단위: 최소 0, 최대 100) 가져오기
     */
    assets: CONST.ASSETS_DATA(),
// 공제
    /**
     * [Object] 공제 정보
     *
     * 관련 mutations >
     * updateDeductionsCohabitationHouseInheritanceAmount: 동거주택상속공제 비용 변경
     *
     * 관련 getters >
     * getDeductionsSpouseAmount: 배우자공제 금액 (n만원 단위) 가져오기
     * getDeductionsBatchAmount: 일괄공제 금액 (n만원 단위) 가져오기
     * getDeductionsFinancialAssetsAmount: 금융자산공제 금액 (n만원 단위) 가져오기
     *getDeductionsCohabitationHouseInheritanceAmount: 동거주택상속공제 금액 (n만원 단위) 가져오기
     */
    deductions: CONST.DEDUCTIONS_DATA(),

// 계산 결과
    /**
     * [Number] 모든 상속인의 법정배분율(고정값) 총 합
     */
    totalStatutoryShareOfInheritance: 0,
    /**
     * [Number] 모든 상속인의 상속배분율(백분율) 총 합
     */
    totalShareOfInheritance: 0,
    /**
     * [Number] 모든 상속재산 총 합
     */
    totalPropertiesAmount: 0,
    /**
     * [Number] 모든 금융자산 총 합
     */
    totalFinancesAmount: 0,
    /**
     * [Number] 모든 과세가액공제 총 합
     */
    totalFinancialObligationsAmount: 0,
    /**
     * [Number] 상속 과세액
     */
    inheritanceAmount: 0,
  },
  mutations: {
    /**
     * 얼럿 및 컴펌
     */
    setAlert(state, data) {
      // const emptyFn = ()=>{};
      state.isShowAlert = true;
      state.alertText = data.text;
      state.alertClickEvent = data.event; // emptyFn;
      state.alertTitle = data.title;
    },
    resetAlert(state) {
      state.isShowAlert = false;
      state.alertText = "";
      state.alertClickEvent = ()=>{};
    },
    setConfirm(state, data) {
      // const emptyFn = ()=>{};
      state.isShowConfirm = true;
      state.confirmText = data.text;
      state.confirmClickEvent = data.event; // emptyFn;
    },
    resetConfirm(state) {
      state.isShowConfirm = false;
      state.confirmText = "";
      state.confirmClickEvent = ()=>{};
    },
    /**
     * 상승률 수정하기 레이어
     */
    setLnb(state, data) {
      state.nowLnb = data
    },

    setShareOfInheritanceMethod(state, data) {
      state.shareOfInheritanceMethod = data
    },

    /**
     * 상승률 수정하기 레이어
     */
    controlAscentRatePopup(state, data) {
      state.ascentRatePopup = data
    },
    /**
     * 데이터 초기화
     * @param {Number} state.totalShareOfInheritance 모든 상속인의 상속배분율(백분율) 총 합
     * @param {Number} state.totalStatutoryShareOfInheritance 모든 상속인의 법정배분율(고정값) 총 합
     * @param {Number} state.totalPropertiesAmount 모든 상속재산 총 합
     * @param {Number} state.totalFinancesAmount 모든 금융자산 총 합
     * @param {Number} state.totalFinancialObligationsAmount 모든 과세가액공제 총 합
     * @param {Number} state.inheritanceAmount 상속 과세액
     * @param {Array} state.inheritorList 상속인 목록
     * @param {Object} state.spouse 배우자 정보
     * @param {Object} state.additionalInheritor 추가상속인 정보
     * @param {Object} state.assets 자산 정보
     *
     */
    initData(state) {
      state.nowAge = 0
      state.yearsLater = [0, 0]
      state.spouse = CONST.SPOUSE_DATA()
      state.additionalInheritor = CONST.ADDITIONAL_INHERITOR_DATA()
      state.inheritorList = CONST.INHERITOR_LIST_DATA()
      state.assets = CONST.ASSETS_DATA()
      state.deductions = CONST.DEDUCTIONS_DATA()
      state.totalShareOfInheritance = CONST.getTotalShareOfInheritance(state.inheritorList)
      state.totalStatutoryShareOfInheritance = CONST.getTotalStatutoryShareOfInheritance(state.spouse, state.additionalInheritor)
      state.totalPropertiesAmount = CONST.getTotalPropertiesAmount(state.assets)
      state.totalFinancesAmount = CONST.getTotalFinancesAmount(state.assets)
      state.totalFinancialObligationsAmount = CONST.getTotalFinancialObligationsAmount(state.assets)
      state.inheritanceAmount = CONST.getInheritanceAmount(state.totalPropertiesAmount, state.totalFinancialObligationsAmount)
    },
    /////////////////////////////// 기본 정보
    /**
     * 고객 현재 나이 변경
     * @param {Number} state.nowAge 고객 현재 나이
     * @param {Number} data 변경할 고객 현재 나이
     */
    updateNowAge(state, nowAge) {
      if (state.nowAge !== nowAge) {
        state.nowAge = nowAge
        Vue.set(state.yearsLater, 0, CONST.LIFE_EXPECTANCY >= nowAge ? CONST.LIFE_EXPECTANCY - nowAge : 0)
      }
    },
    /**
     * 1차 상속 시점 (현재로부터 n년 후) 변경
     * @param {Number} state.yearsLater 1차 상속 시점 (현재로부터 n년 후)
     * @param {Number} yearsLater 변경할 1차 상속 시점 (현재로부터 n년 후)
     */
    updateFirstInheritanceYearsLater(state, yearsLater) {
      if (state.yearsLater[0] !== yearsLater) {
        Vue.set(state.yearsLater, 0, yearsLater)
        Vue.set(state.yearsLater, 1, CONST.LIFE_EXPECTANCY >= state.spouse.age + state.yearsLater[0] ? CONST.LIFE_EXPECTANCY - state.spouse.age - state.yearsLater[0] : 0)
      }
    },
    /**
     * 2차 상속 시점 (1차 상속으로부터 n년 후) 변경
     * @param {Number} state.yearsLater 2차 상속 시점 (1차 상속으로부터 n년 후)
     * @param {Number} yearsLater 변경할 2차 상속 시점 (1차 상속으로부터 n년 후)
     */
    updateSecondInheritanceYearsLater(state, yearsLater) {
      Vue.set(state.yearsLater, 1, yearsLater)
    },
    /**
     * 배우자 유무 변경
     * @param {Object} state.spouse 배우자 정보
     * @param {Object} state.additionalInheritor 추가상속인 정보
     * @param {Number} state.totalStatutoryShareOfInheritance 모든 상속인의 법정배분율(고정값) 총 합
     * @param {Array} state.inheritorList 상속인 목록
     * @param {Number} state.totalShareOfInheritance 모든 상속인의 상속배분율(백분율) 총 합
     */
    switchSpouseExist(state) {
      state.spouse.count = state.spouse.count === 0 ? 1 : 0

      state.totalStatutoryShareOfInheritance = CONST.getTotalStatutoryShareOfInheritance(state.spouse, state.additionalInheritor)
      state.inheritorList = CONST.getInheritorList(state.spouse, state.additionalInheritor, state.totalStatutoryShareOfInheritance)
      state.totalShareOfInheritance = CONST.getTotalShareOfInheritance(state.inheritorList)
      state.deductions[CONST.DEDUCTION_TYPE.SPOUSE].amount = 
        CONST.getDeductionSpouseAmount(
          state.inheritanceAmount, 
          state.assets[CONST.ASSETS_TYPE.FUNERAL_EXPENSES].amount, 
          state.totalShareOfInheritance, 
          state.inheritorList)
    },
    /**
     * 배우자 현재 나이 변경
     * @param {Object} state.spouse 배우자 정보
     * @param {Number} nowAge 변경할 배우자 현재 나이
     */
    updateSpouseAge(state, nowAge) {
      if (state.spouse.age !== nowAge) {
        state.spouse.age = VALID.spouseAge(nowAge)
        Vue.set(state.yearsLater, 1, CONST.LIFE_EXPECTANCY >= nowAge + state.yearsLater[0] ? CONST.LIFE_EXPECTANCY - nowAge - state.yearsLater[0] : 0)
      }
    },
    /**
     * 추가상속인 타입 변경
     * @param {Object} state.spouse
     * @param {Object} state.additionalInheritor
     * @param {Number} state.totalStatutoryShareOfInheritance
     * @param {Array} state.inheritorList
     * @param {Number} state.totalShareOfInheritance
     */
    switchAdditionalInheritorType(state) {
      state.additionalInheritor.type =
        state.additionalInheritor.type === CONST.INHERITOR_TYPE.CHILDREN ?
          CONST.INHERITOR_TYPE.LINEAL_ASCENDANT :
          CONST.INHERITOR_TYPE.CHILDREN

      state.additionalInheritor.count = 1
      state.totalStatutoryShareOfInheritance = CONST.getTotalStatutoryShareOfInheritance(state.spouse, state.additionalInheritor)
      state.inheritorList = CONST.getInheritorList(state.spouse, state.additionalInheritor, state.totalStatutoryShareOfInheritance)
      state.totalShareOfInheritance = CONST.getTotalShareOfInheritance(state.inheritorList)
    },
    /**
     * 추가상속인 수 변경
     * @param {Object} state.spouse 배우자 정보
     * @param {Object} state.additionalInheritor 추가상속인 정보
     * @param {Number} state.totalStatutoryShareOfInheritance 모든 상속인의 법정배분율(고정값) 총 합
     * @param {Array} state.inheritorList 상속인 목록
     * @param {Number} state.totalShareOfInheritance 모든 상속인의 상속배분율(백분율) 총 합
     */
    updateAdditionalInheritorCount(state, count) {
      state.additionalInheritor.count = VALID.additionalInheritorCount(count)

      state.totalStatutoryShareOfInheritance = CONST.getTotalStatutoryShareOfInheritance(state.spouse, state.additionalInheritor)
      state.inheritorList = CONST.getInheritorList(state.spouse, state.additionalInheritor, state.totalStatutoryShareOfInheritance)
      state.totalShareOfInheritance = CONST.getTotalShareOfInheritance(state.inheritorList)
      state.deductions[CONST.DEDUCTION_TYPE.SPOUSE].amount = 
        CONST.getDeductionSpouseAmount(
          state.inheritanceAmount, 
          state.assets[CONST.ASSETS_TYPE.FUNERAL_EXPENSES].amount, 
          state.totalShareOfInheritance, 
          state.inheritorList)
    },
    /**
     * 2차 상속 결과 노출 여부 변경
     * @param {Boolean} state.visibleSecondInheritanceTaxReport 2차 상속 결과 노출 여부
     */
    switchVisibleSecondInheritanceTaxReport(state) {
      state.visibleSecondInheritanceTaxReport = !state.visibleSecondInheritanceTaxReport
    },
    /////////////////////////////// 자산 정보
    /**
     * 자산 정보 변경
     * @param {Object} state.assets 자산 정보
     * @param {Object} state.deductions 공제 정보
     * @param {Number} state.totalFinancesAmount 모든 금융자산 총 합
     * @param {Number} state.totalPropertiesAmount 모든 상속재산 총 합
     * @param {Number} state.totalFinancialObligationsAmount 모든 과세가액공제 총 합
     * @param {Number} state.inheritanceAmount 상속 과세액
     * @param {Number} state.totalShareOfInheritance 모든 상속인의 상속배분율(백분율) 총 합
     * @param {Object} data type: 변경할자산타입, key: 변경할 요소(amount, ascentRate), value: 변경할 내용
     */
    updateAssets(state, data) {
      const asset = state.assets[data.type]
      if (asset) {
        if (data.key === 'amount') {
          asset[data.key] = VALID.amount(data.value)

        } else if (data.key === 'ascentRate') {
          asset[data.key] = VALID.ascentRateP(data.value) / 100
        } else {
          throw new Error('잘못된 키 타입입니다. amount 또는 ascentRate로 입력해주세요.')
        }
      } else {
        throw new Error('잘못된 자산타입입니다.')
      }

      state.totalPropertiesAmount = CONST.getTotalPropertiesAmount(state.assets)
      state.totalFinancesAmount = CONST.getTotalFinancesAmount(state.assets)
      state.totalFinancialObligationsAmount = CONST.getTotalFinancialObligationsAmount(state.assets)
      state.inheritanceAmount = CONST.getInheritanceAmount(state.totalPropertiesAmount, state.totalFinancialObligationsAmount)
      state.deductions[CONST.DEDUCTION_TYPE.SPOUSE].amount = 
        CONST.getDeductionSpouseAmount(
          state.inheritanceAmount, 
          state.assets[CONST.ASSETS_TYPE.FUNERAL_EXPENSES].amount, 
          state.totalShareOfInheritance, 
          state.inheritorList)
      state.deductions[CONST.DEDUCTION_TYPE.FINANCIAL_ASSETS].amount = CONST.getDeductionFinancialAssetsAmount(state.totalFinancesAmount)
    },
    /**
     * 자산 증가치 전체 변경
     * @param {Object} state.assets 자산 정보
     * @param {Number} rate 변경할 증가치 (단위 %: 최소 0, 최대 100)
     */
    // updateAscentRateOfAllAssets(state, data) {
    //   const rate = VALID.ascentRateP(data)
    //   forEach(state.assets, (asset) => { asset.ascentRate = asset.editableAscentRate ? rate / 100 : asset.ascentRate })
    // },
    /////////////////////////////// 상속 비율 정보
    /**
     * 상속인 상속배분치 목록 변경
     * @param {Array} state.inheritorList 상속인 목록
     * @param {Object} state.deductions 공제 정보
     * @param {Number} state.inheritanceAmount 상속 과세액
     * @param {Number} state.totalShareOfInheritance 모든 상속인의 상속배분율(백분율) 총 합
     * @param {Array} shareOfInheritancesList 전체 상속인 목록 [{shareOfInheritance: 변경할상속비율}]
     */
     updateShareOfInheritancesListByInheritor(state, shareOfInheritancesList) {
      if (state.inheritorList.length === shareOfInheritancesList.length) {

        for (let i = 0; i < state.inheritorList.length; i++) {
          state.inheritorList[i].shareOfInheritance = shareOfInheritancesList[i].shareOfInheritance / 100
        }

        state.totalShareOfInheritance = CONST.getTotalShareOfInheritance(state.inheritorList)
        state.deductions[CONST.DEDUCTION_TYPE.SPOUSE].amount =
          CONST.getDeductionSpouseAmount(
            state.inheritanceAmount, 
            state.assets[CONST.ASSETS_TYPE.FUNERAL_EXPENSES].amount,
            state.totalShareOfInheritance, 
            state.inheritorList)
      } else {
        throw new Error('index_error', '상속인 데이터가 일치하지 않습니다.')
      }
    },
    /**
     * 상속인 상속배분치 목록 초기화
     * @param {Number} state.totalStatutoryShareOfInheritance 모든 상속인의 법정배분율(고정값) 총 합
     * @param {Array} state.inheritorList 상속인 목록
     * @param {Object} state.spouse 배우자 정보
     * @param {Object} state.additionalInheritor 추가상속인 정보
     * @param {Number} state.totalShareOfInheritance 모든 상속인의 상속배분율(백분율) 총 합
     * @param {Number} state.inheritanceAmount 상속 과세액
     * @param {Object} state.deductions 공제 정보
     * @param {Boolean} shareOfInheritanceMethod 상속배분치 적용방식 (자동적용: false, 직접입력: true)
     */
    resetShareOfInheritancesListByInheritor(state, shareOfInheritanceMethod) {
      if (!shareOfInheritanceMethod) {
        state.totalStatutoryShareOfInheritance = CONST.getTotalStatutoryShareOfInheritance(state.spouse, state.additionalInheritor)
        state.inheritorList = CONST.getInheritorList(state.spouse, state.additionalInheritor, state.totalStatutoryShareOfInheritance)
        state.totalShareOfInheritance = CONST.getTotalShareOfInheritance(state.inheritorList)
        state.deductions[CONST.DEDUCTION_TYPE.SPOUSE].amount =
          CONST.getDeductionSpouseAmount(
            state.inheritanceAmount, 
            state.assets[CONST.ASSETS_TYPE.FUNERAL_EXPENSES].amount,
            state.totalShareOfInheritance, 
            state.inheritorList)
      }
    },
    /////////////////////////////// 공제 정보
    /**
     * 동거주택상속공제 비용 변경
     * @param {Object} state.deductions 공제 정보
     * @param {Number} amount 변경할 비용
     */
    updateDeductionsCohabitationHouseInheritanceAmount(state, amount) {
      const deduction = state.deductions[CONST.DEDUCTION_TYPE.COHABITATION_HOUSE_INHERITANCE]
      deduction.amount = VALID.amount(amount)
    }
  },
  getters: {
    /////////////////////////////// LNB
    getLnbState(state) {
      return state.nowLnb
    },
    isShareOfInheritanceMethod(state) {
      return state.shareOfInheritanceMethod
    },
    /////////////////////////////// 기본 정보
    /**
     * 고객 현재 나이 가져오기
     * @param {Number} state.nowAge 고객 현재 나이
     * @returns [Number] 고객 현재 나이
     */
    getNowAge(state) {
      return state.nowAge
    },
    /**
     * 1차 상속 시점 가져오기 (현재로부터 n년 후)
     * @param {Number} state.yearsLater[0] 1차 상속 시점 (현재로부터 n년 후)
     * @returns [Number] 1차 상속 시점 (현재로부터 n년 후)
     */
    getFirstInheritanceYearsLater(state) {
      return state.yearsLater[0]
    },
    /**
     * 2차 상속 시점 가져오기 (현재로부터 n년 후)
     * @param {Number} state.yearsLater[1] 2차 상속 시점 (현재로부터 n년 후)
     * @returns [Number] 2차 상속 시점 (현재로부터 n년 후)
     */
    getSecondInheritanceYearsLater(state) {
      return state.yearsLater[1]
    },
    /**
     * 배우자 존재 여부 가져오기 (존재하는 경우 true, 존재하지 않는 경우 false)
     * @param {Number} state.spouse.count 배우자 수 (존재하는 경우 1, 존재하지 않는 경우 0)
     * @returns [Boolean] 배우자 존재 여부(존재하는 경우 true, 존재하지 않는 경우 false)
     */
    isSpouseExist(state) {
      return state.spouse.count > 0
    },
    /**
     * 배우자 현재 나이 가져오기 존재하는 경우 true, 존재하지 않는 경우 false)
     * @param {Number} state.spouse.age 배우자 현재 나이
     * @returns [Number] 배우자 현재 나이
     */
    getSpouseAge(state) {
      return state.spouse.age
    },
    /**
     * 추가상속인 타입 가져오기 (0: 자녀, 1: 추가상속인)
     * 상속인 타입 상수 >
     * 자녀: INHERITOR_TYPE.CHILDREN,
     * 추가상속인: NHERITOR_TYPE.LINEAL_ASCENDANT
     * @param {Object} state.additionalInheritor 추가상속인 정보
     * @returns [Number] 0: 자녀, 1: 추가상속인
     */
    getAdditionalInheritorType(state) {
      return state.additionalInheritor.type === CONST.INHERITOR_TYPE.CHILDREN ? 0 : 1
    },
    /**
     * 추가상속인 인원수 가져오기
     * @param {Number} state.additionalInheritor.count 추가상속인 인원수
     * @returns [Number] 추가상속인 인원수
     */
    getAdditionalInheritorCount(state) { // 추가상속인 인원수
      return state.additionalInheritor.count
    },
    /**
     * 2차 상속 결과 노출 여부 가져오기
     * @param {*} state.visibleSecondInheritanceTaxReport 2차 상속 결과 노출 여부
     * @returns [Boolean] 노출하는 경우 true, 노출하지 않는 경우 false
     */
    isVisibleSecondInheritanceTaxReport(state) {
      return state.visibleSecondInheritanceTaxReport
    },
/////////////////////////////// 자산 정보
    /**
     * 자산 상승률 전체 변경 시 기본 상승률 가져오기
     * @returns [Number] 자산 상승률 전체 변경 시 기본 상승률
     */
    getCommonAscentRate() {
      return parseInt(CONST.FIRST_ASSETS_ASCENT_RATE * 100)
    },
//////////// 부동산 자산
    /**
     * 토지 금액 (n만원 단위) 가져오기
     * @param {Object} state.assets 자산 정보
     * @returns [Number] 토지 금액 (n만원 단위)
     */
    getAssetLandAmount(state) {
      return state.assets[CONST.ASSETS_TYPE.LAND].amount
    },
    /**
     * 토지 증가치 (% 단위: 최소 0, 최대 100) 가져오기
     * @param {Object} state.assets 자산 정보
     * @returns [Number] 토지 증가치 (% 단위: 최소 0, 최대 100)
     */
    getAssetLandAscentRate(state) {
      return parseInt(state.assets[CONST.ASSETS_TYPE.LAND].ascentRate * 100)
    },/**
    * 건물 금액 (n만원 단위) 가져오기
    * @param {Object} state.assets 자산 정보
    * @returns [Number] 건물 금액 (n만원 단위) (n만원 단위)
    */
    getAssetBuildingAmount(state) {
      return state.assets[CONST.ASSETS_TYPE.BUILDING].amount
    },
    /**
     * 건물 증가치 (% 단위: 최소 0, 최대 100) 가져오기
     * @param {Object} state.assets 자산 정보
     * @returns [Number] 건물 증가치 (% 단위: 최소 0, 최대 100)
     */
    getAssetBuildingAscentRate(state) {
      return parseInt(state.assets[CONST.ASSETS_TYPE.BUILDING].ascentRate * 100)
    },
    /**
     * 주택 금액 (n만원 단위) 가져오기
     * @param {Object} state.assets 자산 정보
     * @returns [Number] 주택 금액 (n만원 단위) (n만원 단위)
     */
    getAssetHousingAmount(state) {
      return state.assets[CONST.ASSETS_TYPE.HOUSING].amount
    },
    /**
     * 주택 증가치 (% 단위: 최소 0, 최대 100) 가져오기
     * @param {Object} state.assets 자산 정보
     * @returns [Number] 주택 증가치 (% 단위: 최소 0, 최대 100)
     */
    getAssetHousingAscentRate(state) {
      return parseInt(state.assets[CONST.ASSETS_TYPE.HOUSING].ascentRate * 100)
    },
    //////////// 가업주식
    /**
     * 가업주식 금액 (n만원 단위) 가져오기
     * @param {Object} state.assets 자산 정보
     * @returns [Number] 가업주식 금액 (n만원 단위)
     */
    getAssetFamilyStockAmount(state) {
      return state.assets[CONST.ASSETS_TYPE.FAMILY_STOCK].amount
    },
    /**
     * 가업주식 증가치 (% 단위: 최소 0, 최대 100) 가져오기
     * @param {Object} state.assets 자산 정보
     * @returns [Number] 가업주식 증가치 (% 단위: 최소 0, 최대 100)
     */
    getAssetFamilyStockAscentRate(state) {
      return parseInt(state.assets[CONST.ASSETS_TYPE.FAMILY_STOCK].ascentRate * 100)
    },
    //////////// 금융 자산
    /**
     * 예금 금액 (n만원 단위) 가져오기
     * @param {Object} state.assets 자산 정보
     * @returns [Number] 예금 금액 (n만원 단위)
     */
    getAssetDepositAmount(state) {
      return state.assets[CONST.ASSETS_TYPE.DEPOSIT].amount
    },
    /**
     * 예금 증가치 (% 단위: 최소 0, 최대 100) 가져오기
     * @param {Object} state.assets 자산 정보
     * @returns [Number] 예금 증가치 (% 단위: 최소 0, 최대 100)
     */
    getAssetDepositAscentRate(state) {
      return parseInt(state.assets[CONST.ASSETS_TYPE.DEPOSIT].ascentRate * 100)
    },
    /**
     * 주식 금액 (n만원 단위) 가져오기
     * @param {Object} state.assets 자산 정보
     * @returns [Number] 주식 금액 (n만원 단위)
     */
    getAssetStockAmount(state) {
      return state.assets[CONST.ASSETS_TYPE.STOCK].amount
    },
    /**
     * 주식 증가치 (% 단위: 최소 0, 최대 100) 가져오기
     * @param {Object} state.assets 자산 정보
     * @returns [Number] 주식 증가치 (% 단위: 최소 0, 최대 100)
     */
    getAssetStockAscentRate(state) {
      return parseInt(state.assets[CONST.ASSETS_TYPE.STOCK].ascentRate * 100)
    },
    /**
     * 기타금융자산 금액 (n만원 단위) 가져오기
     * @param {Object} state.assets 자산 정보
     * @returns [Number] 기타금융자산 금액 (n만원 단위)
     */
    getAssetOtherFinancialAssetsAmount(state) {
      return state.assets[CONST.ASSETS_TYPE.OTHER_FINANCIAL_ASSETS].amount
    },
    /**
     * 기타금융자산 증가치 (% 단위: 최소 0, 최대 100) 가져오기
     * @param {Object} state.assets 자산 정보
     * @returns [Number] 기타금융자산 증가치 (% 단위: 최소 0, 최대 100)
     */
    getAssetOtherFinancialAssetsAscentRate(state) {
      return parseInt(state.assets[CONST.ASSETS_TYPE.OTHER_FINANCIAL_ASSETS].ascentRate * 100)
    },
    /**
     * 보험자산 금액 (n만원 단위) 가져오기
     * @param {Object} state.assets 자산 정보
     * @returns [Number] 보험자산 금액 (n만원 단위)
     */
    getAssetInsuranceAssetsAmount(state) {
      return state.assets[CONST.ASSETS_TYPE.INSURANCE_ASSETS].amount
    },
    /**
     * 보험자산 증가치 (% 단위: 최소 0, 최대 100) 가져오기
     * @param {Object} state.assets 자산 정보
     * @returns [Number] 보험자산 증가치 (% 단위: 최소 0, 최대 100)
     */
    getAssetInsuranceAssetsAscentRate(state) {
      return parseInt(state.assets[CONST.ASSETS_TYPE.INSURANCE_ASSETS].ascentRate * 100)
    },
    /**
     * 종신보험금 금액 (n만원 단위) 가져오기
     * @param {Object} state.assets 자산 정보
     * @returns [Number] 종신보험금 금액 (n만원 단위)
     */
    getAssetLifeInsuranceAmount(state) {
      return state.assets[CONST.ASSETS_TYPE.LIFE_INSURANCE].amount
    },
    //////////// 기타 자산
    /**
     * 기타자산 금액 (n만원 단위) 가져오기
     * @param {Object} state.assets 자산 정보
     * @returns [Number] 기타자산 금액 (n만원 단위)
     */
    getAssetOtherAssetsAmount(state) {
      return state.assets[CONST.ASSETS_TYPE.OTHER_ASSETS].amount
    },
    /**
     * 기타자산 증가치 (% 단위: 최소 0, 최대 100) 가져오기
     * @param {Object} state.assets 자산 정보
     * @returns [Number] 기타자산 증가치 (% 단위: 최소 0, 최대 100)
     */
    getAssetOtherAssetsAscentRate(state) {
      return parseInt(state.assets[CONST.ASSETS_TYPE.OTHER_ASSETS].ascentRate * 100)
    },
    //////////// 과세내역
    /**
     * 장례비용 금액 (n만원 단위) 가져오기
     * @param {Object} state.assets 자산 정보
     * @returns [Number] 장례비용
     */
    getAssetFuneralExpensesAmount(state) {
      return state.assets[CONST.ASSETS_TYPE.FUNERAL_EXPENSES].amount
    },
    /**
     * 채무 금액 (n만원 단위) 가져오기
     * @param {Object} state.assets 자산 정보
     * @returns [Number] 채무 금액 (n만원 단위)
     */
    getAssetFinancialObligationAmount(state) {
      return state.assets[CONST.ASSETS_TYPE.FINANCIAL_OBLIGATION].amount
    },
    //////////// 배우자 자산
    /**
     * 배우자 비금융자산 금액 (n만원 단위) 가져오기
     * @param {Object} state.assets 자산 정보
     * @returns [Number] 배우자 비금융자산 금액 (n만원 단위)
     */
    getAssetSpouseNonFinancialAssetsAmount(state) {
      return state.assets[CONST.ASSETS_TYPE.SPOUSE_NON_FINANCIAL_ASSETS].amount
    },
    /**
     * 배우자 비금융자산 증가치 (% 단위: 최소 0, 최대 100) 가져오기
     * @param {Object} state.assets 자산 정보
     * @returns [Number] 배우자 비금융자산 증가치 (% 단위: 최소 0, 최대 100)
     */
    getAssetSpouseNonFinancialAssetsAscentRate(state) {
      return parseInt(state.assets[CONST.ASSETS_TYPE.SPOUSE_NON_FINANCIAL_ASSETS].ascentRate * 100)
    },
    /**
     * 배우자 금융자산 금액 (n만원 단위) 가져오기
     * @param {Object} state.assets 자산 정보
     * @returns [Number] 배우자 금융자산 금액 (n만원 단위)
     */
    getAssetSpouseFinancialAssetsAmount(state) {
      return state.assets[CONST.ASSETS_TYPE.SPOUSE_FINANCIAL_ASSETS].amount
    },
    /**
     * 배우자 금융자산 증가치 (% 단위: 최소 0, 최대 100) 가져오기
     * @param {Object} state.assets 자산 정보
     * @returns [Number] 배우자 금융자산 증가치 (% 단위: 최소 0, 최대 100)
     */
    getAssetSpouseFinancialAssetsAscentRate(state) {
      return parseInt(state.assets[CONST.ASSETS_TYPE.SPOUSE_FINANCIAL_ASSETS].ascentRate * 100)
    },
    /////////////////////////////// 상속재산 비율
    /**
     * 모든 상속인의 상속배분치 목록 가져오기
     * @param {Array} state.inheritorList 상속인 목록
     * @returns [Array] 모든 상속인의 상속배분치 목록
     * type: 상속인타입,
     * name: 상속인 이름,
     * shareOfInheritance: 상속배분치 (% 단위: 최소 0, 최대 100)
     */
    getShareOfInheritancesListByInheritor(state) {
      return map(state.inheritorList, (inheritor) => {
        return {
          type: inheritor.type,
          name: inheritor.name,
          shareOfInheritance: Math.round(inheritor.shareOfInheritance * 100)
        }
      })
    },
    /**
     * 총 상속 배분율 (최대 100 단위)
     * @param {*} state
     * @returns
     */
    getTotalShareOfInheritance(state) {
      return Math.round(state.totalShareOfInheritance * 100)
    },
    /////////////////////////////// 공제
    /**
     * 배우자공제 금액 (n만원 단위) 가져오기
     * @param {Object} state.deductions 공제 정보
     * @returns [Number] 배우자공제 금액 (n만원 단위)
     */
    getDeductionsSpouseAmount(state) {
      return Math.round(state.deductions[CONST.DEDUCTION_TYPE.SPOUSE].amount)
    },
    /**
     * 일괄공제 금액 (n만원 단위) 가져오기
     * @param {Object} state.deductions 공제 정보
     * @returns [Number] 일괄공제 금액 (n만원 단위)
     */
    getDeductionsBatchAmount(state) {
      return Math.round(state.deductions[CONST.DEDUCTION_TYPE.BATCH].amount)
    },
    /**
     * 금융자산공제 금액 (n만원 단위) 가져오기
     * @param {Object} state.deductions 공제 정보
     * @returns [Number] 금융자산공제 금액 (n만원 단위)
     */
    getDeductionsFinancialAssetsAmount(state) {
      return Math.round(state.deductions[CONST.DEDUCTION_TYPE.FINANCIAL_ASSETS].amount)
    },
    /**
     * 동거주택상속공제 금액 (n만원 단위) 가져오기
     * @param {Object} state.deductions 공제 정보
     * @returns [Number] 동거주택상속공제 금액 (n만원 단위)
     */
    getDeductionsCohabitationHouseInheritanceAmount(state) {
      return Math.round(state.deductions[CONST.DEDUCTION_TYPE.COHABITATION_HOUSE_INHERITANCE].amount)
    },
///////////////////////////////
    /**
     * n년 후 1차 상속세 예상 추이 계산을 위한 yearsLater 시퀀스 목록
     * @param {Number} state.nowAge 고객 현재 나이
     * @returns [Array] n년 후 1차 상속세 예상 추이 계산을 위한 yearsLater 목록
     */
    getSequenceYearsLaterListOfFirstInheritanceTax(state) {
      const interval = (100 - state.nowAge) / 4

      return [0, Math.round(interval), Math.round(interval * 2), Math.round(interval * 3),  100 - state.nowAge]
    },
    getSecondInheritanceStartSpouseAge(state) {
      return state.spouse.age + state.yearsLater[0]
    },
    /**
     * n년 후 2차 상속세 예상 추이 계산을 위한 yearsLater 시퀀스 목록
     * @param {Number} state.spouse.age 배우자 현재 나이
     * @returns [Array] n년 후 1차 상속세 예상 추이 계산을 위한 yearsLater 목록
     */
    getSequenceYearsLaterListOfSecondInheritanceTax(state, getters) {
      const secondInheritanceStartAge = getters.getSecondInheritanceStartSpouseAge
      const interval = (100 - secondInheritanceStartAge) / 4

      return [0, Math.round(interval), Math.round(interval * 2), Math.round(interval * 3),  100 - secondInheritanceStartAge]
    },
/////////////////////////////// 결과 레포트
    /**
     * @param {Array} state.yearsLater 상속 예상 n년 후 목록
     * @param {Object} state.assets 현재 년도 자산
     * @param {Object} state.deductions 현재 년도 공제
     * @param {Array} state.inheritorList 현재 년도 상속인 목록
     * @param {Number} state.totalStatutoryShareOfInheritance 현재 년도 모든 상속인에 대한 법정배분율(고정값) 총 합
     * @param {Number} state.totalShareOfInheritance 현재 년도 모든 상속인에 대한 배분율(백분율) 총 합
     * @param {Array} getters.getSequenceYearsLaterListOfFirstInheritanceTax n년 후 1차 상속세 예상 추이 계산을 위한 yearsLater 목록
     * @param {Array} getters.getSequenceYearsLaterListOfSecondInheritanceTax n년 후 2차 상속세 예상 추이 계산을 위한 yearsLater 목록
     * @returns [Array] firstInheritanceTaxList: 현재부터 예상 시점까지 1차 상속세 추이 목록
       @returns [Object] firstInheritanceTaxResultExpectationYearsLater: 1차 상속 예상 시점 1차 상속세 결과,
       @returns [Array] secondInheritanceTaxList: 1차 상속 시점부터 예상 시점까지 2차 상속세 결과 추이 목록,
       @returns [Object] secondInheritanceTaxResultExpectationYearsLater: 2차 상속 예상 시점 1차 상속세 결과)
     */
    getReport(state, getters) {
      const nowYearsAssets = state.assets
      const nowYearsDeductions = state.deductions
      const nowYearsInheritorList = state.inheritorList
      const nowYearsTotalShareOfInheritance = state.totalShareOfInheritance
      const expectationYearsLaterOfFirstInheritanceTax = state.yearsLater[0]
      const expectationYearsLaterOfSecondInheritanceTax = state.yearsLater[1]
      const sequenceYearsLaterListOfFirstInheritanceTax = getters.getSequenceYearsLaterListOfFirstInheritanceTax
      const sequenceYearsLaterListOfSecondInheritanceTax = getters.getSequenceYearsLaterListOfSecondInheritanceTax

      /**
       * n년 후 1차 상속세 결과
       * @param {Number} years 1차 상속 예상 시점 (현재로부터 n년 후)
       * @returns [Object] assetsResult: 자산 결과
       * @returns [Object] deductionsResult: 공제 결과
       * @returns [Object] inheritanceTaxResult: 상속세 결과
       * @returns [Object] inheritorListResult: 상속인 별 상속세 결과
       */
      const firstInheritanceTaxResult = (years) => {

        /**
         * 입력 자산에서 도출된 n년 후 자산 정보
         * @param {Number} years 1차 상속 예상 시점 (현재로부터 n년 후)
         * @returns amount: 자산 금액
         * @returns ascentRate: 자산 증가율
         * @returns editableAscentRate: 자산 증가율 수정 가능 여부
         */
        const getAssetsNYearsLater = (years) => {
          return transform(nowYearsAssets, (result, asset, assetType) => {
            result[assetType] = {
              amount: CONST.getAmountNYearsLater(asset.amount, asset.ascentRate, years),
              ascentRate: asset.ascentRate,
              editableAscentRate: asset.editableAscentRate
            }}, {})
        }

        /**
         * getAssetsNYearsLater 에서 도출된 n년 후 자산 결과
         * @param {Object} assets n년 후 자산 정보
         * @returns [Object] assets: n년 후 자산 정보
         * @returns [Number] totalPropertiesAmount: 상속재산 총액
         * @returns [Number] totalFinancialObligationsAmount: 과세가액 공제 총액
         * @returns [Number] totalFinancesAmount: 금융자산 총액
         * @returns [Number] inheritanceAmount: 상속 과세액)
         */
        const getAssetsResult = (assets) => {
          const totalPropertiesAmount = CONST.getTotalPropertiesAmount(assets)
          const totalFinancialObligationsAmount = CONST.getTotalFinancialObligationsAmount(assets)
          const tempInheritanceAmount = CONST.getInheritanceAmount(totalPropertiesAmount, totalFinancialObligationsAmount)

          return {
            assets: assets,
            totalPropertiesAmount: totalPropertiesAmount,
            totalFinancialObligationsAmount: totalFinancialObligationsAmount,
            totalFinancesAmount: CONST.getTotalFinancesAmount(assets),
            inheritanceAmount: tempInheritanceAmount
          }
        }

        /**
         * getAssetsResult 에서 도출된 n년 후 공제 결과
         * @param {Object} assetsResult n년 후 자산 정보 결과
         * @returns [Object] deductions: n년 후 공제 정보
         * @returns [Number] totalDeductionsAmount: 총 공제액
         */
        const getDeductionsResult = (assetsResult) => {
          const deductions = cloneDeep(nowYearsDeductions)
          deductions[CONST.DEDUCTION_TYPE.SPOUSE].amount = 
            CONST.getDeductionSpouseAmount(
              assetsResult.inheritanceAmount, 
              assetsResult.assets[CONST.ASSETS_TYPE.FUNERAL_EXPENSES].amount,
              nowYearsTotalShareOfInheritance, 
              nowYearsInheritorList)
          deductions[CONST.DEDUCTION_TYPE.FINANCIAL_ASSETS].amount = CONST.getDeductionFinancialAssetsAmount(assetsResult.totalFinancesAmount)
          const totalDeductionsAmont = CONST.getTotalAmount(deductions, CONST.DEDUCTION_GROUP.DEDUCTION)

          return {
            deductions: deductions,
            totalDeductionsAmount: totalDeductionsAmont
          }
        }

        /**
         * getAssetsResult 와 getDeductionsResult 에서 도출된 n년 후 상속세 결과
         * @param {Object} assetsResult n년 후 자산 정보 결과
         * @param {Object} deductionsResult n년 후 공제 정보 결과
         * @returns [Number] finalTaxAmount: 최종 차가감납부 세액
         * @returns [Number] inheritanceAmountAfterPayTax: 세후 상속재산 총액
         */
        const getInheritanceTaxResult = (assetsResult, deductionsResult) => {
          const inheritanceAmount = assetsResult.inheritanceAmount
          const totalDeductionsAmount = deductionsResult.totalDeductionsAmount
          const tempTaxBase = inheritanceAmount - totalDeductionsAmount
          const taxBase = tempTaxBase >= 0 ? tempTaxBase : 0 // 과세표준
          const taxRate = CONST.getTaxRate(taxBase) // 세율
          const taxAmount = taxBase * taxRate - CONST.getTaxRateCredit(taxRate) // 산출세액
          const taxCredit = taxAmount * 0.03 // 세액공제
          const finalTaxAmount = taxAmount - taxCredit

          return {
            finalTaxAmount: finalTaxAmount,
            inheritanceAmountAfterPayTax: inheritanceAmount - finalTaxAmount
          }
        }

        /**
         * getAssetsResult 와 getInheritanceTaxResult 에서 도출된 상속인의 n년 후 상속세 결과
         * @param {Object} assetsResult n년 후 자산 정보 결과
         * @param {Object} inheritanceTaxResult n년 후 상속세 결과
         * @returns [String] type: 상속인 타입 (배우자, 자녀, 직계존속)
         * @returns [String] name: 상속인 이름
         * @returns [Number] inheritanceAmount: 상속 과세액
         * @returns [Number] reserveInheritanceAmount: 유류분
         * @returns [Number] finalTaxAmount: 최종 차가감납부 세액
         * @returns [Number] inheritanceAmountAfterPayTax: 세후 상속재산 총액}
         */
        const getInheritorListResult = (assetsResultInheritanceAmount, inheritanceTaxResultFinalTaxAmount) => {
          return map(nowYearsInheritorList, (inheritor) => {
            const shareOfInheritance = inheritor.shareOfInheritance / state.totalShareOfInheritance
            const inheritanceAmount = assetsResultInheritanceAmount * shareOfInheritance
            const finalTaxAmount = inheritanceTaxResultFinalTaxAmount * shareOfInheritance

            return {
              type: inheritor.type,
              name: inheritor.name,
              inheritanceAmount: inheritanceAmount,
              reserveInheritanceAmount: assetsResultInheritanceAmount * (inheritor.statutoryShareOfInheritance / state.totalStatutoryShareOfInheritance) / 2,
              finalTaxAmount: finalTaxAmount,
              inheritanceAmountAfterPayTax: inheritanceAmount - finalTaxAmount,
            }
          })
        }

        const assetsResult = getAssetsResult(getAssetsNYearsLater(years))
        const deductionsResult = getDeductionsResult(assetsResult)
        const inheritanceTaxResult = getInheritanceTaxResult(assetsResult, deductionsResult)

        return {
          yearsLater: years,
          assetsResult: assetsResult,
          deductionsResult: deductionsResult,
          inheritanceTaxResult: inheritanceTaxResult,
          inheritorListResult: getInheritorListResult(assetsResult.inheritanceAmount, inheritanceTaxResult.finalTaxAmount)
        }
      }

      /**
       * n년 후 2차 상속세 결과
       * @param {Object} firstInheritanceTaxResultExpectationYearsLater 상속 예상 시점 1차 상속세 결과
       * @param {Number} years 2차 상속 예상 시점 (1차 상속일로부터 n년 후)
       * @returns [Number] years: 1차 상속일로부터 n년 후
         @returns [Number] inheritanceAmount: 상속 과세액
         @returns [Number] finalTaxAmount: 최종 차가감납부 세액
         @returns [Number] inheritanceAmountAfterPayTax: 세후 상속재산 총액
       */
      const secondInheritanceTaxResult = (firstInheritanceTaxResultExpectationYearsLater, years) => {
        const assets = firstInheritanceTaxResultExpectationYearsLater.assetsResult.assets
        const spouseNonFinancialAssets = assets[CONST.ASSETS_TYPE.SPOUSE_NON_FINANCIAL_ASSETS].amount
        const spouseFinancialAssets = assets[CONST.ASSETS_TYPE.SPOUSE_FINANCIAL_ASSETS].amount
        const spouseFirstInheritanceAmountAfterPayTax = find(firstInheritanceTaxResultExpectationYearsLater.inheritorListResult,
          ['type', CONST.INHERITOR_TYPE.SPOUSE]).inheritanceAmountAfterPayTax // 1차 상속재산

        const inheritanceAmount =
          CONST.getAmountNYearsLater(
            spouseNonFinancialAssets + spouseFirstInheritanceAmountAfterPayTax,
            CONST.SECOND_ASSETS_ASCENT_RATE,
            years) // 2차 상속시 과세액

        const deductibleGeneral = 50000 // 공제액 일반
        const deductibleFinance = spouseFinancialAssets >= 100000 ? 20000 :
          (spouseFinancialAssets >= 2000 ? Math.max(spouseFinancialAssets * 0.2, 2000) : spouseFinancialAssets) // 공제액 금융
        const deductibleFuneral = 1000; // 공제액 장례비
        const tempTaxBase = inheritanceAmount - deductibleGeneral - deductibleFinance - deductibleFuneral
        const taxBase = tempTaxBase >= 0 ? tempTaxBase : 0 // 2차 과세표준: 음수인 경우 0 처리
        const taxRate = CONST.getTaxRate(taxBase) // 세율

        const finalTaxAmount = (taxBase * taxRate) - (taxBase * taxRate * 0.03) - 
          (taxRate === 0.2 ? 
            1000 : taxRate === 0.3 ? 
            6000 : taxRate === 0.4 ? 
            16000 : taxRate === 0.5 ? 
            46000 : 0); // 최종차감납부세액

        const inheritanceAmountAfterPayTax = inheritanceAmount - finalTaxAmount
        
        return {
          yearsLater: years,
          inheritanceAmount: inheritanceAmount, // 세후재산총액 
          finalTaxAmount: finalTaxAmount, // 총상속세
          inheritanceAmountAfterPayTax: inheritanceAmountAfterPayTax // 과세액
        }
      }

      const firstInheritanceTaxList =
        map(sequenceYearsLaterListOfFirstInheritanceTax, (years) => {
          return firstInheritanceTaxResult(years) }) // 1차 상속세 예상 추이 목록
      const firstInheritanceTaxResultExpectationYearsLater = firstInheritanceTaxResult(expectationYearsLaterOfFirstInheritanceTax) // 1차 상속세 예상상속시점
      let secondInheritanceTaxList = null
      let secondInheritanceTaxResultExpectationYearsLater = null

      if (state.spouse.count === 1 || state.visibleSecondInheritanceTaxReport) {
        secondInheritanceTaxList = map(sequenceYearsLaterListOfSecondInheritanceTax, (years) => {
            return secondInheritanceTaxResult(firstInheritanceTaxResultExpectationYearsLater, years) }) // 2차 상속세 예상 추이 목록
        secondInheritanceTaxResultExpectationYearsLater =
          secondInheritanceTaxResult(firstInheritanceTaxResultExpectationYearsLater, expectationYearsLaterOfSecondInheritanceTax) // 2차 상속세 예상상속시점
      }

      return {
        firstInheritanceTaxList: firstInheritanceTaxList,
        firstInheritanceTaxResultExpectationYearsLater: firstInheritanceTaxResultExpectationYearsLater,
        secondInheritanceTaxList: secondInheritanceTaxList,
        secondInheritanceTaxResultExpectationYearsLater: secondInheritanceTaxResultExpectationYearsLater
      }
    },
    /**
     * 레포트 - 현재 시점 예상 상속 재산
     * @param {Number} state.nowAge 고객 현재 나이
     * @param {Object} state.spouse 배우자 정보
     * @param {Object} state.additionalInheritor 추가상속인 정보
     * @param {Object} getters.getReport 전체 상속 결과 레포트
     * @returns [Number] age: 고객 현재 나이
       @returns [Boolean] spouseExist: 배우자 존재 여부
       @returns [String] additionalInheritorType: 추가상속인 타입
       @returns [Number] additionalInheritorCount: 추가상속인 수
       @returns [Number] totalPropertiesAmount: 총 상속 재산
       @returns [Number] financialObligationsAmount: 채무
       @returns [Number] inheritanceAmount: 상속 과세액
       @returns [Number] finalTaxAmount: 총 상속 세액
       @returns [Number] inheritanceAmountAfterPayTax: 세후 상속 재산
       @returns [Array] inheritorList: 상속인 상속 목록
          [String] type: 상속인 타입 (배우자, 자녀, 직계존속)
          [String] name: 상속인 이름
          [Number] inheritanceAmount: 상속 과세액
          [Number] reserveInheritanceAmount: 유류분
          [Number] finalTaxAmount: 최종 차가감납부 세액
          [Number] inheritanceAmountAfterPayTax: 세후 상속재산 총액
      */
    getReport1(state, getters) {
      const firstInheritanceTax = getters.getReport.firstInheritanceTaxList[0]
      const assetsResult = firstInheritanceTax.assetsResult
      const inheritanceTaxResult = firstInheritanceTax.inheritanceTaxResult
      const inheritorListResult = firstInheritanceTax.inheritorListResult

      return {
        age: state.nowAge,
        spouseExist: state.spouse.count > 0,
        additionalInheritorType: state.additionalInheritor.type,
        additionalInheritorCount: state.additionalInheritor.count,
        totalPropertiesAmount: Math.round(assetsResult.totalPropertiesAmount),
        financialObligationsAmount: Math.round(assetsResult.assets[CONST.ASSETS_TYPE.FINANCIAL_OBLIGATION].amount),
        inheritanceAmount: Math.round(assetsResult.inheritanceAmount),
        finalTaxAmount: Math.round(inheritanceTaxResult.finalTaxAmount),
        inheritanceAmountAfterPayTax: Math.round(inheritanceTaxResult.inheritanceAmountAfterPayTax),
        inheritorList: map(inheritorListResult, inheritorResult => {
          return {
            type: inheritorResult.type,
            name: inheritorResult.name,
            inheritanceAmount: Math.round(inheritorResult.inheritanceAmount),
            reserveInheritanceAmount: Math.round(inheritorResult.reserveInheritanceAmount),
            finalTaxAmount: Math.round(inheritorResult.finalTaxAmount),
            inheritanceAmountAfterPayTax: Math.round(inheritorResult.inheritanceAmountAfterPayTax)
          }
        })
      }
    },
    /**
     * 예상 시점 1차 상속 재산 페이지
     * @param {Number} state.nowAge 고객 현재 나이
     * @param {Object} state.spouse 배우자 정보
     * @param {Object} state.additionalInheritor 추가상속인 정보
     * @param {Object} getters.getReport 전체 상속 결과 레포트
     * @returns [Number] expectationYearsLater: 예상 상속 년수
     * @returns [Number] expectationYearsAge: 예상 상속 나이
     * @returns [Boolean] spouseExist: 배우자 존재 여부
     * @returns [String] additionalInheritorType: 추가상속인 타입
     * @returns [Number] additionalInheritorCount: 추가상속인 수
     * @returns [Number] totalPropertiesAmount: 총 상속 재산
     * @returns [Number] financialObligationsAmount: 채무
     * @returns [Number] inheritanceAmount: 상속 과세액
     * @returns [Number] finalTaxAmount: 총 상속 세액
     * @returns [Number] inheritanceAmountAfterPayTax: 세후 상속 재산
     * @returns [Array] inheritorList: 상속인 상속 목록
     *    [String] type: 상속인 타입 (배우자, 자녀, 직계존속)
     *    [String] name: 상속인 이름
     *    [Number] inheritanceAmount: 상속 과세액
     *    [Number] reserveInheritanceAmount: 유류분
     *    [Number] finalTaxAmount: 최종 차가감납부 세액
     *    [Number] inheritanceAmountAfterPayTax: 세후 상속재산 총액
     */
    getReport2(state, getters) {
      const firstInheritanceTax = getters.getReport.firstInheritanceTaxResultExpectationYearsLater
      const assetsResult = firstInheritanceTax.assetsResult
      const inheritanceTaxResult = firstInheritanceTax.inheritanceTaxResult
      const inheritorListResult = firstInheritanceTax.inheritorListResult

      return {
        expectationYearsLater: firstInheritanceTax.yearsLater,
        expectationYearsAge: state.nowAge + firstInheritanceTax.yearsLater,
        spouseExist: state.spouse.count > 0,
        additionalInheritorType: state.additionalInheritor.type,
        additionalInheritorCount: state.additionalInheritor.count,
        totalPropertiesAmount: Math.round(assetsResult.totalPropertiesAmount),
        financialObligationsAmount: Math.round(assetsResult.assets[CONST.ASSETS_TYPE.FINANCIAL_OBLIGATION].amount),
        inheritanceAmount: Math.round(assetsResult.inheritanceAmount),
        finalTaxAmount: Math.round(inheritanceTaxResult.finalTaxAmount),
        inheritanceAmountAfterPayTax: Math.round(inheritanceTaxResult.inheritanceAmountAfterPayTax),
        inheritorList: map(inheritorListResult, inheritorResult => {
          return {
            type: inheritorResult.type,
            name: inheritorResult.name,
            inheritanceAmount: Math.round(inheritorResult.inheritanceAmount),
            reserveInheritanceAmount: Math.round(inheritorResult.reserveInheritanceAmount),
            finalTaxAmount: Math.round(inheritorResult.finalTaxAmount),
            inheritanceAmountAfterPayTax: Math.round(inheritorResult.inheritanceAmountAfterPayTax)
          }
        })
      }
    },
    /**
     * 예상 상속재산 세부사항
     * @param {Object} getters.getReport 전체 상속 결과 레포트
     * @returns [Object] nowFirstInheritanceTax: 현재 1차 상속세 결과
     *    [Object] assets: 자산 정보
     *    [Object] deductions: 공제 정보
     *    [Number] inheritanceAmount: 총 상속 과세액
     *    [Number] totalDeductionsAmount: 공제 총액
     *    [Number] finalTaxAmount: 최종 상속세액
     *    [Number] inheritanceAmountAfterPayTax: 세후 상속재산
     * @returns [Object] firstInheritanceTaxExpectationYearsLater: 예상 상속 시점 1차 상속세 결과
     *    [Object] assets: 자산 정보
     *    [Object] deductions: 공제 정보
     *    [Number] inheritanceAmount: 총 상속 과세액
     *    [Number] totalDeductionsAmount: 공제 총액
     *    [Number] finalTaxAmount: 최종 상속세액
     *    [Number] inheritanceAmountAfterPayTax: 세후 상속재산
     */
    getReport3(state, getters) {
      const inheritanceTaxReport = (inheritanceTax) => {
        return {
          assets: transform(inheritanceTax.assetsResult.assets, (result, asset, assetType) => {
            result[assetType] = {
              amount: Math.round(asset.amount),
              ascentRate: asset.ascentRate * 100,
              editableAscentRate: asset.editableAscentRate
            }}, {}),
          deductions: transform(inheritanceTax.deductionsResult.deductions, (result, deduction, deductionType) => {
            result[deductionType] = {
              amount: Math.round(deduction.amount)
            }}, {}),
          inheritanceAmount: Math.round(inheritanceTax.assetsResult.inheritanceAmount),
          totalDeductionsAmount: Math.round(inheritanceTax.deductionsResult.totalDeductionsAmount),
          finalTaxAmount: Math.round(inheritanceTax.inheritanceTaxResult.finalTaxAmount),
          inheritanceAmountAfterPayTax: Math.round(inheritanceTax.inheritanceTaxResult.inheritanceAmountAfterPayTax)
        }
      }

      const nowFirstInheritanceTax = getters.getReport.firstInheritanceTaxList[0]
      const firstInheritanceTaxExpectationYearsLater = getters.getReport.firstInheritanceTaxResultExpectationYearsLater

      return {
        nowFirstInheritanceTax: inheritanceTaxReport(nowFirstInheritanceTax),
        firstInheritanceTaxExpectationYearsLater: inheritanceTaxReport(firstInheritanceTaxExpectationYearsLater)
      }
    },
    /**
     * 1차 상속세 예상 추이
     * @param {Number} state.nowAge 고객 현재 나이
     * @param {Array} state.yearsLater 상속 예상 시점
     * @param {Object} getters.getReport 전체 상속 결과 레포트
     * @returns [Number] expectationYearsLater: 예상 상속 년수
     * @returns [Number] expectationYearsAge: 예상 상속 나이
     * @returns [Object] nowFirstInheritanceTax: 현재 시점 1차 상속세 결과
     *    [Number] inheritanceAmount: 상속 과세액 (상속 과세액)
     *    [Number] finalTaxAmount: 총 상속세
     *    [Number] finalTaxRate: 총 상속세 비율
     *    [Number] inheritanceAmountAfterPayTax: 세후 재산 총액
     *    [Number] inheritanceAmountAfterPayTaxRate: 세후 재산 비율
     * @returns [Object] firstInheritanceTaxExpectationYearsLater: 예상 상속 시점 1차 상속세 결과
     *    [Number] inheritanceAmount: 상속 과세액 (상속 과세액)
     *    [Number] finalTaxAmount: 총 상속세
     *    [Number] finalTaxRate: 총 상속세 비율
     *    [Number] inheritanceAmountAfterPayTax: 세후 재산 총액
     *    [Number] inheritanceAmountAfterPayTaxRate: 세후 재산 비율
     * @returns [Array] firstInheritanceTaxList: 현재부터 예상 시점까지 1차 상속세 추이 목록
     *    [Number] age: 고객 나이
     *    [Number] inheritanceAmount: 상속 과세액 (상속 과세액)
     *    [Number] finalTaxAmount: 총 상속세
     *    [Number] finalTaxRate: 총 상속세 비율
     *    [Number] inheritanceAmountAfterPayTax: 세후 재산 총액
     *    [Number] inheritanceAmountAfterPayTaxRate: 세후 재산 비율
     */
    getReport4(state, getters) {
      const inheritanceTaxReport = (inheritanceTaxResult) => {
        const inheritanceAmount = inheritanceTaxResult.assetsResult.inheritanceAmount
        const finalTaxAmount = inheritanceTaxResult.inheritanceTaxResult.finalTaxAmount
        const inheritanceAmountAfterPayTax = inheritanceTaxResult.inheritanceTaxResult.inheritanceAmountAfterPayTax

        return {
          age: state.nowAge + inheritanceTaxResult.yearsLater,
          inheritanceAmount: Math.round(inheritanceAmount),
          finalTaxAmount: Math.round(finalTaxAmount),
          finalTaxRate: Math.round(finalTaxAmount / inheritanceAmount),
          inheritanceAmountAfterPayTax: Math.round(inheritanceAmountAfterPayTax),
          inheritanceAmountAfterPayTaxRate: Math.round(inheritanceAmountAfterPayTax / inheritanceAmount)
        }
      }

      const firstInheritanceTaxList = getters.getReport.firstInheritanceTaxList
      const nowFirstInheritanceTax = firstInheritanceTaxList[0]
      const firstInheritanceTaxExpectationYearsLater = getters.getReport.firstInheritanceTaxResultExpectationYearsLater

      return {
        expectationYearsLater: state.yearsLater[0],
        expectationYearsAge: state.nowAge + state.yearsLater[0],
        nowFirstInheritanceTax: inheritanceTaxReport(nowFirstInheritanceTax),
        firstInheritanceTaxExpectationYearsLater: inheritanceTaxReport(firstInheritanceTaxExpectationYearsLater),
        firstInheritanceTaxList: map(firstInheritanceTaxList, inheritanceTaxResult => inheritanceTaxReport(inheritanceTaxResult)),
      }
    },
    /**
     * 2차 상속세 예상 추이
     * @param {Number} state.nowAge 고객 현재 나이
     * @param {Array} state.yearsLater 상속 예상 시점
     * @param {Object} getters.getReport 전체 상속 결과 레포트
     *
     * @returns 배우자 없음이거나 2차 상속세를 보지않는 경우 null
     *
     * @returns [Number] expectationYearsLater: 예상 상속 년수
     * @returns [Number] expectationYearsAge: 배우자의 예상 상속 나이
     * @returns [Object] firstInheritanceTaxForSpouse: 현재 시점 2차 상속세 결과 // nowSecondInheritanceTax
     *    inheritanceAmount: 상속 과세액 (상속 과세액)
     *    finalTaxAmount: 총 상속세
     *    finalTaxRate: 총 상속세 비율
     *    inheritanceAmountAfterPayTax: 세후 재산 총액
     *    inheritanceAmountAfterPayTaxRate: 세후 재산 비율
     * @returns [Object] secondInheritanceTaxExpectationYearsLater: 예상 상속 시점 1차 상속세 결과
     *    [Number] inheritanceAmount: 상속 과세액 (상속 과세액)
     *    [Number] finalTaxAmount: 총 상속세
     *    [Number] finalTaxRate: 총 상속세 비율
     *    [Number] inheritanceAmountAfterPayTax: 세후 재산 총액
     *    [Number] inheritanceAmountAfterPayTaxRate: 세후 재산 비율
     * @returns [Array] secondInheritanceTaxList: 1차 상속시점부터 예상 시점까지 2차 상속세 추이 목록
     *    [Number] age: 고객 나이
     *    [Number] inheritanceAmount: 상속 과세액 (상속 과세액)
     *    [Number] finalTaxAmount: 총 상속세
     *    [Number] finalTaxRate: 총 상속세 비율
     *    [Number] inheritanceAmountAfterPayTax: 세후 재산 총액
     *    [Number] inheritanceAmountAfterPayTaxRate: 세후 재산 비율
     */
    getReport5(state, getters) {
      const inheritanceTaxReport = (inheritanceTaxResult) => {
        const inheritanceAmount = inheritanceTaxResult.inheritanceAmount
        const finalTaxAmount = inheritanceTaxResult.finalTaxAmount
        const inheritanceAmountAfterPayTax = inheritanceTaxResult.inheritanceAmountAfterPayTax

        return {
          age: getters.getSecondInheritanceStartSpouseAge + inheritanceTaxResult.yearsLater,
          inheritanceAmount: Math.round(inheritanceAmount),
          finalTaxAmount: Math.round(finalTaxAmount),
          finalTaxRate: Math.round(finalTaxAmount / inheritanceAmount * 100),
          inheritanceAmountAfterPayTax: Math.round(inheritanceAmountAfterPayTax),
          inheritanceAmountAfterPayTaxRate: Math.round(inheritanceAmountAfterPayTax / inheritanceAmount * 100)
        }
      }

      if (state.spouse.count === 1 || state.visibleSecondInheritanceTaxReport) {
        const secondInheritanceTaxList = getters.getReport.secondInheritanceTaxList
        const nowSecondInheritanceTax = secondInheritanceTaxList[0]
        const secondInheritanceTaxExpectationYearsLater = getters.getReport.secondInheritanceTaxResultExpectationYearsLater

        return {
          expectationYearsLater: state.yearsLater[1],
          expectationYearsAge: getters.getSecondInheritanceStartSpouseAge + state.yearsLater[1],
          nowSecondInheritanceTax: inheritanceTaxReport(nowSecondInheritanceTax),
          secondInheritanceTaxExpectationYearsLater: inheritanceTaxReport(secondInheritanceTaxExpectationYearsLater),
          secondInheritanceTaxList: map(secondInheritanceTaxList, inheritanceTax => inheritanceTaxReport(inheritanceTax)),
        }
      } else {
        return null
      }
    },
    /**
     * 상속세 납부여력 현황
     * @param {Array} state.yearsLater 상속 예상 시점
     * @param {Object} getters.getReport 전체 상속 결과 레포트
     * @returns [Number] expectationYearsLater: 예상 상속 년수
     * @returns [Object] nowSecondInheritanceTax: 현재 시점 1차 상속세 결과
     *    [Number] inheritanceAmount: 상속 재산 (상속 과세액)
     *    [Number] finalTaxAmount: 상속세
     *    [Number] totalFinancialAssetsAmountExceptLifeInsurance: 금융재산 (금융재산에서 종신보험금 뺀 금액)
     *    [Number] lifeInsuranceAmount: 종신보험금
     *    [Number] shortfallAmount: 부족 금액 (부족 금액 없는 경우 0)
     * @returns [Object] nowSecondInheritanceTax: 예상 상속 시점 1차 상속세 결과 // firstInheritanceTaxExpectationYearsLater
     *    [Number] inheritanceAmount: 상속 재산 (상속 과세액)
     *    [Number] finalTaxAmount: 상속세
     *    [Number] totalFinancialAssetsAmountExceptLifeInsurance: 금융재산 (금융재산에서 종신보험금 뺀 금액)
     *    [Number] lifeInsuranceAmount: 종신보험금
     *    [Number] shortfallAmount: 부족 금액 (부족 금액 없는 경우 0)
     */
    getReport6(state, getters) {
      const inheritanceTaxReport = (inheritanceTaxResult) => {
        return {
          inheritanceAmount: Math.round(inheritanceTaxResult.assetsResult.inheritanceAmount),
          finalTaxAmount: Math.round(inheritanceTaxResult.inheritanceTaxResult.finalTaxAmount),
          totalFinancialAssetsAmountExceptLifeInsurance: Math.round(inheritanceTaxResult.assetsResult.totalFinancesAmount -
            inheritanceTaxResult.assetsResult.assets[CONST.ASSETS_TYPE.LIFE_INSURANCE].amount),
          lifeInsuranceAmount: Math.round(inheritanceTaxResult.assetsResult.assets[CONST.ASSETS_TYPE.LIFE_INSURANCE].amount),
          shortfallAmount: Math.round(inheritanceTaxResult.inheritanceTaxResult.finalTaxAmount - inheritanceTaxResult.assetsResult.totalFinancesAmount > 0 ?
            inheritanceTaxResult.inheritanceTaxResult.finalTaxAmount - inheritanceTaxResult.assetsResult.totalFinancesAmount : 0)
        }
      }

      const nowFirstInheritanceTax = getters.getReport.firstInheritanceTaxList[0]
      const firstInheritanceTaxExpectationYearsLater = getters.getReport.firstInheritanceTaxResultExpectationYearsLater

      return {
        expectationYearsLater: state.yearsLater[0], // 예상 상속 년수
        nowFirstInheritanceTax: inheritanceTaxReport(nowFirstInheritanceTax),
        firstInheritanceTaxExpectationYearsLater: inheritanceTaxReport(firstInheritanceTaxExpectationYearsLater)
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
