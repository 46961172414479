<template>
  <div id="app">
    <router-view id="content"/>

    <transition name="fade">
      <AlertView v-if="this.$store.state.isShowAlert"/>
      <ConfirmView v-if="this.$store.state.isShowConfirm"/>
    </transition>
  </div>
</template>

<script>
import AlertView from '@/components/AlertView'
import ConfirmView from '@/components/ConfirmView'

export default {
  name: 'App',
  components: {
    AlertView,
    ConfirmView
  },
  mounted() {
    window.pruAlert = (text, title, event) => {
      this.$store.commit('setAlert', {
        'text':text,
        'title':title,
        'event': event
      })
    };
    window.pruConfirm = (text, event) => {
      this.$store.commit('setConfirm', {
        'text':text,
        'event': event
      })
    };
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/app";
</style>
