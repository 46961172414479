import { reduce, flatten, map, find, forEach } from 'lodash'

/**
 * 기대여명
 */
const LIFE_EXPECTANCY = 83

/** 자산 상승률 기본값 */
const FIRST_ASSETS_ASCENT_RATE = 0.02
const SECOND_ASSETS_ASCENT_RATE = 0.02

/** 자산 타입 */
const ASSETS_TYPE = {
  LAND: '토지',
  BUILDING: '건물',
  HOUSING: '주택',
  FAMILY_STOCK: '가업주식',
  DEPOSIT: '예금',
  STOCK: '주식',
  OTHER_FINANCIAL_ASSETS: '기타금융자산',
  INSURANCE_ASSETS: '보험자산',
  LIFE_INSURANCE: '종신보험금',
  OTHER_ASSETS: '기타자산',
  FUNERAL_EXPENSES: '장례비용',
  FINANCIAL_OBLIGATION: '채무',
  SPOUSE_NON_FINANCIAL_ASSETS: '배우자비금융자산',
  SPOUSE_FINANCIAL_ASSETS: '배우자금융자산',
}

/** 상속인 타입 */
const INHERITOR_TYPE = {
  SPOUSE: '배우자',
  CHILDREN: '자녀',
  LINEAL_ASCENDANT: '직계존속'
}

/** 공제 타입 */
const DEDUCTION_TYPE = {
  SPOUSE: '배우자공제',
  BATCH: '일괄공제',
  FINANCIAL_ASSETS: '금융자산공제',
  COHABITATION_HOUSE_INHERITANCE: '동거주택상속공제',
}

/** 자산 그룹 */
const ASSETS_GROUP = {
  PROPERTIES: {
    REAL_ESTATE: [ASSETS_TYPE.LAND, ASSETS_TYPE.BUILDING, ASSETS_TYPE.HOUSING],
    FAMILY_BUSINESS: [ASSETS_TYPE.FAMILY_STOCK],
    FINANCE: [
      ASSETS_TYPE.DEPOSIT,
      ASSETS_TYPE.STOCK,
      ASSETS_TYPE.OTHER_FINANCIAL_ASSETS,
      ASSETS_TYPE.INSURANCE_ASSETS,
      ASSETS_TYPE.LIFE_INSURANCE
    ],
    OTHER: [ASSETS_TYPE.OTHER_ASSETS],
  },
  FINANCIAL_OBLIGATION: [ASSETS_TYPE.FUNERAL_EXPENSES, ASSETS_TYPE.FINANCIAL_OBLIGATION],
  SPOUSE: [ASSETS_TYPE.SPOUSE_NON_FINANCIAL_ASSETS, ASSETS_TYPE.SPOUSE_FINANCIAL_ASSETS]
}

/** 공제 그룹 */
const DEDUCTION_GROUP = {
  DEDUCTION: Object.values(DEDUCTION_TYPE)
}

/**
 * 법정배분율(고정값) 정보 가져오기
 * @returns [Object] 법정 배분율 고정값 정보
 */
 const getStatutoryShareOfInheritances = () => {
  const result = {}
  result[INHERITOR_TYPE.SPOUSE] = 1.5
  result[INHERITOR_TYPE.CHILDREN] = 1.0
  result[INHERITOR_TYPE.LINEAL_ASCENDANT] = 1.0

  return result
}
const STATUTORY_SHARE_OF_INHERITANCES = getStatutoryShareOfInheritances()

/**
 * 자산 초기 데이터
 * @returns [Object] 자산 초기 데이터
 */
const ASSETS_DATA = () => {
  const getAsset = (amount, editableAmount, editableAscentRate) => {
    return {
      amount: amount,
      editableAmount: editableAmount,
      editableAscentRate: editableAscentRate,
      ascentRate: editableAscentRate ? FIRST_ASSETS_ASCENT_RATE : 0,
    }
  }

  const result = {}
  result[ASSETS_TYPE.LAND] = getAsset(0, true, true)
  result[ASSETS_TYPE.BUILDING] = getAsset(0, true, true)
  result[ASSETS_TYPE.HOUSING] = getAsset(0, true, true)
  result[ASSETS_TYPE.FAMILY_STOCK] = getAsset(0, true, true)
  result[ASSETS_TYPE.DEPOSIT] = getAsset(0, true, true)
  result[ASSETS_TYPE.STOCK] = getAsset(0, true, true)
  result[ASSETS_TYPE.OTHER_FINANCIAL_ASSETS] = getAsset(0, true, true)
  result[ASSETS_TYPE.INSURANCE_ASSETS] = getAsset(0, true, true)
  result[ASSETS_TYPE.LIFE_INSURANCE] = getAsset(0, true, false)
  result[ASSETS_TYPE.OTHER_ASSETS] = getAsset(0, true, true)
  result[ASSETS_TYPE.FUNERAL_EXPENSES] = getAsset(1000, false, false) // 기본값 지우지 말것
  result[ASSETS_TYPE.FINANCIAL_OBLIGATION] = getAsset(0, true, false)
  // 2차 상속
  result[ASSETS_TYPE.SPOUSE_NON_FINANCIAL_ASSETS] = getAsset(0, true, true)
  result[ASSETS_TYPE.SPOUSE_FINANCIAL_ASSETS] = getAsset(0, true, true)

  return result
}

/**
 * 배우자 초기 데이터
 * @returns [Object] 배우자 초기 데이터
 */
 const SPOUSE_DATA = () => {
  return {
    type: INHERITOR_TYPE.SPOUSE,
    count: 1, // 배우자가 있는 경우 1, 없는 경우 0
    age: null
  }
}

/**
 * 추가상속인 초기 데이터
 * @returns [Object] 추가상속인 초기 데이터
 */
const ADDITIONAL_INHERITOR_DATA = () => {
  return {
    type: INHERITOR_TYPE.CHILDREN,
    count: 1
  }
}

/**
 * 상속인 목록 초기 데이터
 * @returns [Array] 상속인 목록 초기 데이터
 */
const INHERITOR_LIST_DATA = () => {
  return getInheritorList(
    SPOUSE_DATA(),
    ADDITIONAL_INHERITOR_DATA(),
    getTotalStatutoryShareOfInheritance(SPOUSE_DATA(), ADDITIONAL_INHERITOR_DATA()))
}

/**
 * 공제 초기 데이터
 * @returns [Object] 공제 초기 데이터
 */
 const DEDUCTIONS_DATA = () => {
  const getDeduction = (amount, editableAmount) => {
    return {
      amount: amount,
      editableAmount: editableAmount,
    }
  }

  const result = {}
  result[DEDUCTION_TYPE.SPOUSE] = getDeduction(0, false)
  result[DEDUCTION_TYPE.BATCH] = getDeduction(50000, false)
  result[DEDUCTION_TYPE.FINANCIAL_ASSETS] = getDeduction(0, false)
  result[DEDUCTION_TYPE.COHABITATION_HOUSE_INHERITANCE] = getDeduction(0, true)

  return result
}

/******************************************************************************* */

/**
 * 총액 계산기
 * @param {Object} targetData 계산할 데이터
 * @param {Array} indexList 금액 계산할 타입 목록
 * @returns [Number] 금액 게산할 타입 목록의 총 금액
 */
const getTotalAmount = (targetData, indexList) => {
  return reduce(indexList, (sum, key) => {
    return sum + targetData[key].amount
  }, 0)
}

/**
 * 금융자산 총액 계산기
 * @param {Object} assets 자산 정보
 * @returns [Number] 금융자산 총액
 */
const getTotalFinancesAmount = (assets) => {
  const financeList = ASSETS_GROUP.PROPERTIES.FINANCE
  return getTotalAmount(assets, financeList)
}

/**
 * 상속재산 총액 계산기
 * @param {Object} assets 자산 정보
 * @returns [Number] 금융 자산 총액
 */
const getTotalPropertiesAmount = (assets) => {
  const propertyList = reduce(ASSETS_GROUP.PROPERTIES, (result, value, key) => {
    key
    return flatten([result, value])
  }, [])
  return getTotalAmount(assets, propertyList)
}

/**
 * 과세가액 공제 총액 계산기
 * @param {Object} assets 자산 정보
 * @returns [Number] 과세가액 공제 총액
 */
const getTotalFinancialObligationsAmount = (assets) => {
  return getTotalAmount(assets, ASSETS_GROUP.FINANCIAL_OBLIGATION)
}

/**
 * 상속과세액 총액 계산기
 * @param {Number} totalPropertiesAmount 상속재산 총액
 * @param {Number} totalFinancialObligationAmount 과세가액 공제 총액
 * @returns [Number] 상속과세액 총액
 */
const getInheritanceAmount = (totalPropertiesAmount, totalFinancialObligationAmount) => {
  return totalPropertiesAmount - totalFinancialObligationAmount
}

/******************************************************************************** */

/**
 * 전체 상속인의 상속배분율(고정값) 총 합 계산기
 * @param {Object} spouse 배우자 정보
 * @param {Object} additionalInheritor 추가상속인 정보
 * @returns [Number] 전체 상속인의 상속배분율(고정값) 총 합
 */
const getTotalStatutoryShareOfInheritance = (spouse, additionalInheritor) => {
  const getTotalStatutoryShareOfInheritanceByInheritorType = (inheritorInfo) => {
    return STATUTORY_SHARE_OF_INHERITANCES[inheritorInfo.type] * inheritorInfo.count
  }

  return getTotalStatutoryShareOfInheritanceByInheritorType(spouse) + getTotalStatutoryShareOfInheritanceByInheritorType(additionalInheritor)
}

/**
 * 전체 상속인의 상속배분율(백분율) 계산기
 * @param {Number} statutoryShareOfInheritance 상속인 타입의 법정배분율(고정값)
 * @param {Number} totalStatutoryShareOfInheritance 모든 상속인의 법정배분율 총 합
 * @returns [Number] 전체 상속인의 상속배분율(백분율)
 */
const getShareOfInheritance = (statutoryShareOfInheritance, totalStatutoryShareOfInheritance) => {

  return statutoryShareOfInheritance / totalStatutoryShareOfInheritance
}

/**
 * 상속인 초기화 목록 가져오기
 * @param {Object} spouse 배우자 정보
 * @param {Object} additionalInheritor 추가상속인 정보
 * @param {Number} totalStatutoryShareOfInheritance 모든 상속인의 법정배분율 총 합
 * @returns [Array] 상속인 초기화 목록
 */
 const getInheritorList = (spouse, additionalInheritor, totalStatutoryShareOfInheritance) => {
  /**
   * 상속인 초기화 정보 가져오기
   * @param {Object} inheritorInfo 특정 타입의 상속인 정보 (spouse 또는 additionalInheritor)
   * @returns [Object] 상속인 초기화 정보
   */
  const getInheritorData = (inheritorInfo) => {
    let count = 1;
    return map(Array(inheritorInfo.count), () => {
      const statutoryShareOfInheritance = STATUTORY_SHARE_OF_INHERITANCES[inheritorInfo.type]
      const shareOfInheritance = getShareOfInheritance(statutoryShareOfInheritance, totalStatutoryShareOfInheritance)

      return {
        type: inheritorInfo.type,
        name: inheritorInfo.type + count++,
        shareOfInheritance: shareOfInheritance,
        statutoryShareOfInheritance: statutoryShareOfInheritance
      }
    })
  }

  const spouseList = getInheritorData(spouse)
  const additionalInheritorList = getInheritorData(additionalInheritor)

  return flatten([spouseList, additionalInheritorList])
}

/**************************************************************************** */

/**
 * 모든 상속인의 상속배분율(백분율) 총 합 계산기
 * @param {Array} inheritorList 상속인 목록
 * @returns [Number] 모든 상속인의 상속배분율(백분율) 총 합
 */
const getTotalShareOfInheritance = (inheritorList) => {
  return reduce(inheritorList, (sum, inheritor) => {
    return sum + inheritor.shareOfInheritance
  }, 0)
}

/**
 * 배우자 공제액 계산기
 * @param {Number} inheritanceAmount 총 상속 과세액
 * @param {Number} totalShareOfInheritance 모든 상속인의 상속배분율(백분율) 총 합
 * @param {Array} inheritorList 상속인 목록
 * @returns [Number] 배우자 공제액
 */
const getDeductionSpouseAmount = (inheritanceAmount, funeralExpensesAmount, totalShareOfInheritance, inheritorList) => {

  const inheritorSpouse = find(inheritorList, ['type', INHERITOR_TYPE.SPOUSE])

  return inheritorSpouse === undefined ? 
    0 : 
    Math.max(
      Math.min((inheritanceAmount + funeralExpensesAmount) * (inheritorSpouse.shareOfInheritance / totalShareOfInheritance), 300000),
      50000
    )
}

/**
 * 금융자산 공제액 계산기
 * @param {Number} totalFinancesAmount 금융자산 총액
 * @returns [Number] 금융자산 공제액
 */
const getDeductionFinancialAssetsAmount = (totalFinancesAmount) => {
  return totalFinancesAmount >= 100000 ? 20000 :
    totalFinancesAmount >= 2000 ? Math.max(totalFinancesAmount * 0.2, 2000) :
    totalFinancesAmount
}

/**
 * n년 후 증가액 계산기
 * @param {Number} amount 금액
 * @param {Number} ascentRate 증가율
 * @param {Number} years n년 후
 * @returns
 */
const getAmountNYearsLater = (amount, ascentRate, years) => {
  return amount * Math.pow((1 + ascentRate), years)
}

/**
 * 세율 계산기
 * @param {Number} taxBase 과세표준
 * @returns [Number] 세율
 */
const getTaxRate = (taxBase) => {
  const standardList = [[10000, 0.1], [50000, 0.2], [100000, 0.3], [300000, 0.4], [1/0, 0.5]]

  let amount = 0

  if (taxBase <= amount) {
    return standardList[0][1]
  }

  for (let i = 0; i < standardList.length; i++) {
    if (amount <= taxBase && taxBase <= standardList[i][0]) {
      return standardList[i][1]
    } else {
      amount = standardList[i][0] + 1
    }
  }
}

const getTaxRateCredit = (taxRate) => {
  let taxRateCredit = 0
  switch (taxRate) {
    case 0.2:
      taxRateCredit = 1000;
      break;
    case 0.3: 
      taxRateCredit = 6000;
      break;
    case 0.4:
      taxRateCredit = 16000;
      break;
    case 0.5: 
      taxRateCredit = 46000;
      break;
    default: 0;
  }
  return taxRateCredit
}

const updateAscentRateOfAllAssets = (assets, rate) => {
  return forEach(assets, (asset) => { asset.ascentRate = rate / 100 })
}

export default {
  LIFE_EXPECTANCY: LIFE_EXPECTANCY,
  ASSETS_TYPE: ASSETS_TYPE,
  INHERITOR_TYPE: INHERITOR_TYPE,
  DEDUCTION_TYPE: DEDUCTION_TYPE,
  ASSETS_GROUP: ASSETS_GROUP,
  DEDUCTION_GROUP: DEDUCTION_GROUP,
  FIRST_ASSETS_ASCENT_RATE: FIRST_ASSETS_ASCENT_RATE,
  SECOND_ASSETS_ASCENT_RATE: SECOND_ASSETS_ASCENT_RATE,
  SPOUSE_DATA: SPOUSE_DATA,
  ADDITIONAL_INHERITOR_DATA: ADDITIONAL_INHERITOR_DATA,
  ASSETS_DATA: ASSETS_DATA,
  DEDUCTIONS_DATA: DEDUCTIONS_DATA,
  INHERITOR_LIST_DATA: INHERITOR_LIST_DATA,
  getTotalShareOfInheritance: getTotalShareOfInheritance,
  getTotalStatutoryShareOfInheritance: getTotalStatutoryShareOfInheritance,
  getInheritorList: getInheritorList,
  getDeductionSpouseAmount: getDeductionSpouseAmount,
  getDeductionFinancialAssetsAmount: getDeductionFinancialAssetsAmount,
  getTotalPropertiesAmount: getTotalPropertiesAmount,
  getInheritanceAmount: getInheritanceAmount,
  getTotalFinancesAmount: getTotalFinancesAmount,
  getTotalFinancialObligationsAmount: getTotalFinancialObligationsAmount,
  getTotalAmount: getTotalAmount,
  getAmountNYearsLater: getAmountNYearsLater,
  getTaxRate: getTaxRate,
  getTaxRateCredit: getTaxRateCredit,
  updateAscentRateOfAllAssets: updateAscentRateOfAllAssets
}
