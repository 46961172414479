<template>
  <div class="dimm">
    <div class="alertBox">
      <div class="textBox" v-html="alertText"></div>
      <div class="buttonBox">
        <button @click="onClick">확인</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed:{
    alertText() {
      return this.$store.state.alertText.split('\n').join('<br>');
    },
    alertTitle() {
      return this.$store.state.alertTitle;
    }
  },
  methods: {
    onClick() {
      if(typeof this.$store.state.alertClickEvent === 'function') {
        this.$store.state.alertClickEvent();
      }

      this.$store.commit('controlAscentRatePopup', false)
      this.$store.commit('resetAlert');
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/pruAlert'
</style>
